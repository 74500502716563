<template>
  <div>
    <v-row v-if="numBasesSelecionadas > 0">
      <v-col cols="12">
        <div
          v-for="(baseSelecionada, i) in basesSelecionadas"
          :key="baseSelecionada.nome_tabela"
          class="d-inline-flex shrink ma-1"
        >
          <v-chip
            color="grey lighten-3"
            close
            @click:close="basesSelecionadas.splice(i, 1)"
          >
            <div class="d-inline-flex">
              {{ baseSelecionada.descricao | toUpperCase }}
            </div>
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          ref="search"
          class="ml-n2"
          v-model="search"
          hide-details
          prepend-icon="mdi-magnify"
          label="Pesquisar base"
          single-line
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="transitioncard"
          :style="{ overflow: 'hidden', 'max-height': chipContainerHeight }"
        >
          <template v-for="base in basesFiltradas">
            <v-chip
              outlined
              class="mr-1 mt-1"
              v-if="checkVisibilityBasesLiberadas(base)"
              :key="base.nome_tabela"
              @click="basesSelecionadas.push(base)"
            >
              {{ base.descricao | toUpperCase }}
            </v-chip>
          </template>
        </div>
        <div
          justify="center"
          align="center"
          class="mt-n3"
        >
          <v-btn
            v-if="chipContainerHeight != '100%' && numBases > 0"
            class="mt-2 mb-n3 ml-n7"
            icon
            color="gray"
            @click="chipContainerHeight = '100%'"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            v-else-if="numBases > 0"
            class="mt-2 mb-n3 ml-n7"
            icon
            color="gray"
            @click="chipContainerHeight = '37px'"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BasesGeograficas',
  props: {
    bases: {
      type: Array,
      default: () => []
    },
    basesSelecionadasUpdate: {
      type: Array
    }
  },
  mounted() {
    if (this.basesSelecionadasUpdate) this.fillInputs();
  },
  data() {
    return {
      search: '',
      basesSelecionadas: [],
      chipContainerHeight: '37px'
    };
  },
  computed: {
    numBases() {
      return this.bases.length;
    },
    numBasesSelecionadas() {
      return this.basesSelecionadas.length;
    },
    basesFiltradas() {
      const search = this.search.toLowerCase();
      if (!search) return this.bases;
      return this.bases.filter((base) =>
        base.descricao.toLowerCase().includes(search)
      );
    }
  },
  watch: {
    basesSelecionadas() {
      this.$emit('selecaoBasesAtualizadas', this.basesSelecionadas);
    }
  },
  methods: {
    fillInputs() {
      this.basesSelecionadas = this.basesSelecionadasUpdate;
    },

    checkVisibilityBasesLiberadas(base) {
      let nomesTabela = [];
      for (const baseSelecionada of this.basesSelecionadas) {
        nomesTabela.push(baseSelecionada.nome_tabela);
      }
      return nomesTabela.indexOf(base.nome_tabela) == -1;
    }
  }
};
</script>
